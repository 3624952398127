<template>
  <section>

    <div class="content">
      <div id="section-1" v-view="profilHandler"   class="row profil-container" data-aos="fade-up" data-aos-duration="1500">
        <input  class="input-range profil-range" orient="vertical" type="range" v-model="profilKey" min="1" max="6">
        <img
          class="profil"
          src="@/assets/images/import/pr/profil-parfait.png"
          alt="profil"
          data-aos="fade-left" data-aos-duration="1500"
        />
        <template>
          <div class="mobile">
            <img  data-aos="slide-left" data-aos-duration="3000" class="rayures" src="@/assets/images/import/pr/rayures.png" alt="rayures" />
            <div  class="txt-1 " data-aos="slide-left" data-aos-duration="3000" v-html="profil.text_1"></div>
            <div  class="txt-2" data-aos="slide-left" data-aos-duration="3000" v-html="profil.text_2">
            </div>
          </div>

          <div class="computer">
            <img v-if="profilKey >= 2" data-aos="slide-left" data-aos-duration="3000" class="rayures" src="@/assets/images/import/pr/rayures.png" alt="rayures" />
            <div v-if="profilKey >= 4" class="txt-1 " data-aos="slide-left" data-aos-duration="3000" v-html="profil.text_1">Multilingue</div>
            <div v-if="profilKey >= 6" class="txt-2" data-aos="slide-left" data-aos-duration="3000" v-html="profil.text_2">
            </div>
          </div>

        </template>
      </div>

      <div id="section-2"  v-view class="row carrieres computer "  data-aos="fade-up" data-aos-duration="3000">
        <div class="col-sm-12  col-md-4   car car-1 d-flex justify-content-center flex-column align-self-center">
          <div class="title">
              <div class="text-center">
                <img style="height:100px" src="@/assets/images/import/pr/interview-640_white.png" alt="" srcset="">
            </div>
            <div class="text-center txt-carrieres Tangier_Medium" v-html="carriere.title_1"></div>
          </div>
          <div class="text-hover" v-html="carriere.description_1">
            </div>
        </div>
        <div class="col-sm-12  col-md-4 car car-2 d-flex justify-content-center flex-column align-self-center "  >
          <div class="title">
            <div class="text-center">
                <img class="icon" src="@/assets/images/import/pr/gestion carrière.png" alt="" srcset="">
            </div>
            <div class="text-center txt-carrieres Tangier_Medium"  v-html="carriere.title_2">
            </div>
          </div>
           <div class="text-hover" v-html="carriere.description_2">
            </div>
        </div>
        <div class="col-sm-12  col-md-4 car car-3 d-flex justify-content-center flex-column align-self-center">
          <div class="title">
            <div class="text-center ">
                <img class="icon " src="@/assets/images/import/pr/gestion_administrative.png" alt="" srcset="">
            </div>
            <div class="text-center  txt-carrieres Tangier_Medium" v-html="carriere.title_3"></div>
          </div>
          <div class="text-hover" v-html="carriere.description_3">
          </div>
        </div>
      </div>

      <div  class="row carrieres-mobile m-2 mobile"  >
        <div data-aos="fade-up" data-aos-duration="3000" class="col-sm-12 m-2  car-mobile car-1 d-flex justify-content-center flex-column align-self-center">
          <div class="title-mobile">
              <div class="text-center">
                <img style="height:100px" src="@/assets/images/import/pr/interview-640_white.png" alt="" srcset="">
            </div>
            <div class="text-center txt-carrieres Tangier_Medium" v-html="carriere.title_1"></div>
          </div>
          <div class="text-1" v-html="carriere.description_1">
            </div>
        </div>
        <div data-aos="fade-up" data-aos-duration="3000" class="col-sm-12 m-2  car-mobile car-2 d-flex justify-content-center flex-column align-self-center "  >
          <div class="title-mobile">
            <div class="text-center">
                <img class="icon" src="@/assets/images/import/pr/gestion carrière.png" alt="" srcset="">
            </div>
            <div class="text-center txt-carrieres Tangier_Medium" v-html="carriere.title_2">
            </div>
          </div>
           <div class="text-1" v-html="carriere.description_2">
            
            </div>
        </div>
        <div data-aos="fade-up" data-aos-duration="3000" class="col-sm-12 m-2  car-mobile car-3 d-flex justify-content-center flex-column align-self-center">
          <div class="title-mobile">
            <div class="text-center ">
                <img class="icon " src="@/assets/images/import/pr/gestion_administrative.png" alt="" srcset="">
            </div>
            <div class="text-center  txt-carrieres Tangier_Medium" v-html="carriere.title_3"></div>
          </div>
          <div class="text-1" v-html="carriere.description_3">
          </div>
        </div>
      </div>

      
      <div   v-view="cardHandler"   class="col-12 computer  text-center card-horizontal " data-aos="fade-up" data-aos-duration="1500">
        <transition name="fade">
          <div v-if="orangeCard">
          <input  class="input-range blue-card-range" orient="vertical" type="range" v-model="cardKey" min="0" max="10">
            <div :style="`--orangescroll:${orangescroll}%`" class="orange-card d-flex flex-column justify-content-center" data-aos="fade-in" data-aos-duration="1500">
              <div v-if="cardKey >= 2" class="card-text-1" data-aos="fade-up" data-aos-duration="1500">Consultant recrutement spécialisé </div>
              <div v-if="cardKey >= 4" class="card-text-2" data-aos="fade-up" data-aos-duration="1500">IT/ DIGITAL</div>
              <div v-if="cardKey >= 6" class="card-text-2" data-aos="fade-up" data-aos-duration="1500">COMMERCIAL</div>
              <div v-if="cardKey >= 8" class="card-text-2" data-aos="fade-up" data-aos-duration="1500">AGROALIMENTAIRE</div>
              <div v-if="cardKey >= 10" class="card-text-2" data-aos="fade-up" data-aos-duration="1500">RETAIL</div>
            </div>
          </div>
        </transition>
          
          <!-- <div style="height:300px"></div> -->
      <!-- </div> -->

      <!-- <div class="col-12 text-center card-horizontal m-4" data-aos="fade-in" data-aos-duration="1500" > -->
        <!-- <transition name="fade"> -->
          <div v-if="!orangeCard">
            <div v-if="cardKey >= 14" :style="`--bluescroll:${bluescroll}%`"  class="blue-card d-flex flex-column justify-content-center" data-aos="fade-in" data-aos-duration="3000">
              <div v-if="cardKey >= 16" class="card-text-2" data-aos="fade-in" data-aos-duration="1500">Je parle l'anglais, le français,le malgache</div><br>
              <div  v-if="cardKey >= 18" class="card-text-2" data-aos="fade-in" data-aos-duration="1500">Je me déplace sur toute la France</div><br>
              <div  v-if="cardKey >= 20" class="card-text-2" data-aos="fade-in" data-aos-duration="1500">Je travaille aisément en télétravail </div><br>
            </div>
            <input  class="input-range orange-card-range" orient="vertical" type="range" v-model="cardKey" min="14" max="20">
          </div>
        <!-- </transition> -->
           
      </div>

      <div   class="col-12 mobile text-center card-horizontal-mobile " data-aos="fade-up" data-aos-duration="1500">

          <div  >
            <div class="orange-card-mobile  d-flex flex-column justify-content-center"  data-aos="fade-in" data-aos-duration="1500">
              <br>
              <div  class="card-text-mobile-1" data-aos="fade-up" data-aos-duration="1500">Consultant recrutement spécialisé </div>
              <div  class="card-text-mobile-2" data-aos="fade-up" data-aos-duration="1500">IT/ DIGITAL</div>
              <div  class="card-text-mobile-2" data-aos="fade-up" data-aos-duration="1500">COMMERCIAL</div>
              <div  class="card-text-mobile-2" data-aos="fade-up" data-aos-duration="1500">AGROALIMENTAIRE</div>
              <div  class="card-text-mobile-2" data-aos="fade-up" data-aos-duration="1500">RETAIL</div>
            </div>
          </div>
      </div>

      <div class="col-12 text-center mobile card-horizontal-mobile" data-aos="fade-in" data-aos-duration="1500" >
          <div>
            <div  class="blue-card-mobile  d-flex flex-column justify-content-center" data-aos="fade-in" data-aos-duration="1500">
              <br>
              <br>
              <div class="card-text-mobile-2" data-aos="fade-in" data-aos-duration="1500">Je parle l'anglais, le français,le malgache</div><br>
              <div class="card-text-mobile-2" data-aos="fade-in" data-aos-duration="1500">Je me déplace sur toute la France</div><br>
              <div class="card-text-mobile-2" data-aos="fade-in" data-aos-duration="1500">Je travaille aisément en télétravail </div><br>
            </div>
          </div>           
      </div>

      <div id="section-4"  v-view class="col-12 text-center contact-card computer" data-aos="fade-in" data-aos-duration="3000">
          <p class="card-text-1">TPE, PME, Grands groupes,<br> déléguez le recrutement à un spécialiste !</p>
          <button class="pr-btn" @click="goContact()">Contactez-moi !</button>
      </div>

      <div   v-view class="col-12 text-center contact-card mobile" data-aos="fade-in" data-aos-duration="3000">
          <p class="card-text-mobile-1">TPE, PME, Grands groupes,<br> déléguez le recrutement à un spécialiste !</p>
          <button class="pr-btn" @click="goContact()">Contactez-moi !</button>
      </div>
    </div>
  </section>
</template>
<script>
import axios from 'axios';
export default {
  name: "home",
  components: {},
  data() {
    return {
      profilKey: 0,
      cardKey:0,
      profil:{},
      carriere:{},
      orangeCard:true,
      orangescroll:40,
      bluescroll:40,

 
    };
  },
  methods: {
    scrollOp(operation){
      var supportsPassive = true;
      var wheelOpt = supportsPassive ? { passive: false } : false;
      var wheelEvent = 'onwheel' in document.createElement('div') ? 'wheel' : 'mousewheel';
      if (operation) {
        window.addEventListener('DOMMouseScroll', this.preventDefault, false); // older FF
        window.addEventListener(wheelEvent, this.preventDefault, wheelOpt); // modern desktop
        window.addEventListener('touchmove', this.preventDefault, wheelOpt); // mobile
        window.addEventListener('keydown', this.preventDefaultForScrollKeys, false);
      }else{
        window.removeEventListener('DOMMouseScroll', this.preventDefault, false);
        window.removeEventListener(wheelEvent, this.preventDefault, wheelOpt); 
        window.removeEventListener('touchmove', this.preventDefault, wheelOpt);
        window.removeEventListener('keydown', this.preventDefaultForScrollKeys, false);
      }
    },
    preventDefaultForScrollKeys(e){
      // left: 37, up: 38, right: 39, down: 40,
      // spacebar: 32, pageup: 33, pagedown: 34, end: 35, home: 36
      var keys = {37: 1, 38: 1, 39: 1, 40: 1};
      if (keys[e.keyCode]) {
        this.preventDefault(e);
        return false;
      }
    },
    preventDefault(e) {
      e.preventDefault();
    },
    profilHandler(e){
      // console.log(e.percentInView)
       if (e.percentInView == 1) {
         this.scrollOp(true)
        window.addEventListener('wheel', this.profilScroll)
      }
      
    },
    profilScroll(e){
        if (e.deltaY>0) {
          if (this.profilKey <= 9 ) {
            this.profilKey++
          }
        }else{
          if (this.profilKey > 0) {
            this.profilKey--
          }
        }
        if (this.profilKey >= 9) {
          this.scrollOp(false)
          window.removeEventListener('wheel',this.profilScroll)
          // this.$router.push({ path: '/#section-2' })
        }
    },
    cardHandler(e){
      if ( e.percentInView == 1 ) {
        this.scrollOp(true)
        window.addEventListener('wheel', this.cardScroll)

      }
    },
    cardScroll(e){
       if (e.deltaY>0) {
         if (this.cardKey <= 22 ) {
            this.cardKey++
            if (this.cardKey  < 12) {
            this.orangescroll = this.orangescroll + 0.02
            }else{
              this.bluescroll = this.bluescroll + 0.02
            }
          }
       }else{
         if (this.cardKey > -2) {
            this.cardKey--
            if (this.cardKey  < 12) {
            this.orangescroll = this.orangescroll - 0.02
            }else{
              this.bluescroll = this.bluescroll - 0.02
            }
          }
       }
       if (this.cardKey >= 12) {
         this.orangeCard =false
       }else{
         this.orangeCard =true

       }
       if (this.cardKey == -2) {

          this.scrollOp(true)
          window.removeEventListener('wheel',this.preventDefault)
          }
       if (this.cardKey == 23 ) {
          this.scrollOp(true)
          window.removeEventListener('wheel',this.preventDefault)
       }
    },
    goContact(){
      this.$router.push({
                    name:'contact'
                })
    },
    getSection(){
      axios.get("https://be-monechapeebelle.comolapapaya.com/pages/01FYRYFAWHBHTKSS3BV2RDSMK2")
        .then(
          getSectionres => {
            console.log({getSectionres})
            this.profil = getSectionres.data.sections[0].blocks[0].data[0].data
            this.carriere = getSectionres.data.sections[0].blocks[0].data[1].data

          }
        )
        .catch(
          err =>
          console.error({err})
        )
    }
  },
  mounted() {
    // document.getElementById("test").focus()
    // this.scrollOp(true)
    this.getSection()
  },
};
</script>
<style scoped>

.content {
  min-height: 600px;
  max-width: 1300px;
  /* padding: 20px; */
  margin: auto;
}

section{
background-image: url("../../assets/images/import/pr/fond-orange.png");
  background-repeat: no-repeat;
  margin-top: -100px;
  /* background-position: right;  */
  
  background-attachment: fixed;
  background-position: right top; 
}



.view-in--full {
  transition: all 4s;
  margin-top: 7%;
  margin-bottom: 8%;
  /* border: 1px solid rgb(255, 0, 0); */

}

.view-in--gt-half {
  transition: all 4s;
  margin-top: 6%;
  margin-bottom: 6%;
  /* border: 1px solid rgb(97, 202, 153); */

}

.view-in--gt-third {
  transition: all 3s;
  margin-top: 3%;
  margin-bottom: 3%;
  /* border: 1px solid rgb(10, 99, 187); */

}

.view-out {
  transition: all 2s;
  margin-top: 0;
  margin-bottom: 0;
  /* border: 1px solid rgb(37, 4, 4); */
}
@media screen and (max-width: 992px) {
  /**MOBILE STYLE */

  /**profile section style */
  .profil{
    position: absolute;
    z-index: 1;
    width: 50%;
    top:20px;
    left: 5%;
  }
  .profil-container{
    height: 300px;
    position: relative;
  }

  .rayures {
    position: absolute;
    z-index: -1;
    top: 10%;
    width: 80%;
    right: 0px;
  }

  .txt-1 {
    top: 50px;
    left: 30%;
    text-transform: uppercase;
    font-size: 3rem;
    color: rgb(187, 185, 185);
  }

  .txt-2 {
    top: 30px;
    left: 50%;
    font-family: "Noto Sans";
    color: black;
    font-weight: 600;
    font-size: 1.5rem;
  }
  /***************************/

  /** CARRIERE */
  .carrieres > div{
    min-height: 390px;
  }

  .title{
    margin-top: 50px;
  }

}

@media screen and (min-width: 992px) {
  /**COMPUTER STYLE */

  /**profile section style */
  .profil-container {
    height: 80vh ;
    position: relative;
  }
  
  .profil {
    position: absolute;
    z-index: 1;
    height: 100%;
    bottom: 0;
    left: 14%;
  }

  .rayures {
    position: absolute;
    z-index: -1;
    top: 10%;
    height: 65%;
    right: 0px;
  }

  .txt-1 {
    top: 30%;
    left: 30%;
    text-transform: uppercase;
    font-size: 5rem;
    color: rgb(187, 185, 185);
  }


  .txt-2 {
    top: 30%;
    left: 60%;
    font-family: "Noto Sans";
    color: black;
    font-weight: 600;
    font-size: 4rem;
  }
  /***************************/

  /** CARRIERE */
  .carrieres > div {
    height: 390px;
  }

  .title{
    transition:all 0.5s ease-in-out;
    margin-top:40%;
  }

}





.txt-1,
.txt-2 {
  position: absolute;
}

.txt-2 span {
  color: #f93a00;
}

.carrieres {
  padding-left: 80px;
  padding-right: 80px;
}

.icon{
  width: 90px;
}

.card-horizontal{
  /* overflow-y: scroll;
  scroll-behavior: auto;
  overflow-x:hidden; */
  padding-left: 10%;
  padding-right: 10%;
  /* scroll-snap-stop: normal; */
  height: 400px;
}

.card-horizontal-mobile{
  padding: 20px;
}
.car-1 {
  background-image: linear-gradient(138deg, rgba(158,94,74,0.9247898988697041) 33%, rgba(201,121,14,0.7847338764607406) 95%, rgba(253,123,0,0.5970588064327293) 100%),
    url("../../assets/images/import/pr/car-1.jpg");
  background-size: cover;
}
.car-1:hover {
  background-image: linear-gradient(#876b55), url("../../assets/images/import/pr/car-1.jpg"); 
  
}
.car:hover .text-hover{
  transition:all 0.1s;
  visibility: visible;
  margin-bottom:  0rem;
  opacity: 1;
}



.car:hover .title{
  margin-top:0%;

}

.car .text-hover{
  /* display: none; */
  /* margin-top: 15rem; */
  visibility: hidden;
  transition: all 2s;
  opacity: 0;
}

.car-2 {
  background-color: #876d55;
  background-image:   linear-gradient(138deg, rgba(158,94,74,0.9247898988697041) 33%, rgba(201,121,14,0.7847338764607406) 95%, rgba(253,123,0,0.5970588064327293) 100%),
    url("../../assets/images/import/pr/car-2.jpg");
  background-size: cover;
}
.car-2:hover {
  background-image: linear-gradient(#876b55), url("../../assets/images/import/pr/car-2.jpg");
}
.car-3 {
  background-color: #876d55;
  background-image: linear-gradient(138deg, rgba(158,94,74,0.9247898988697041) 33%, rgba(201,121,14,0.7847338764607406) 95%, rgba(253,123,0,0.5970588064327293) 100%),
    url("../../assets/images/import/pr/car-3.jpg");
  background-size: cover;
}
.car-3:hover {
  background-image: linear-gradient(#876b55), url("../../assets/images/import/pr/car-3.jpg");
}


.blue-card{
    /* position: sticky; */
    /* position: -webkit-sticky; */
    /* top: 0; */
    height: 300px;
    background-color: #6295FE;
    box-shadow: -8px 8px 6px -5px rgba(0,0,0,0.75);
    margin-top: 0px;
    background-image:  url("../../assets/images/import/pr/forest_blue.png");
    background-size: var(--bluescroll);
    background-repeat: no-repeat;
    background-position-x: right;
    background-position-y: bottom;
    /* transition: background-size 0.3s ease; */

}

.blue-card-mobile{
    min-height: 300px;
    background-color: #6295FE;
    box-shadow: -8px 8px 6px -5px rgba(0,0,0,0.75);
    margin-top: 0px;
    background-image:  url("../../assets/images/import/pr/forest_blue.png");
    background-size: 70%;
    background-repeat: no-repeat;
    background-position-x: right;
    background-position-y: bottom;
}
.orange-card{
    /* position: -webkit-sticky; */
    /* position: sticky; */
    /* top: 0; */
    height:300px;
    background: #f93a00;
    box-shadow: -8px 8px 6px -5px rgba(0,0,0,0.75);
    border-radius: 3px;
    background-image:  url("../../assets/images/import/pr/forest-_orange_degrade.png");
    background-size: var(--orangescroll);
    background-repeat: no-repeat;
    background-position-x: left;
    background-position-y: bottom;
    /* transition: background-size 0.3s ease; */
    
}

.orange-card-mobile{
  
    min-height:300px;
    background-color: #FB6E00;
    box-shadow: -8px 8px 6px -5px rgba(0,0,0,0.75);
     border-radius: 3px;
    background-image:  url("../../assets/images/import/pr/forest-_orange_degrade.png");
    background-size: 70%;
    background-repeat: no-repeat;
    background-position-x: left;
    background-position-y: bottom;
}
.card-text-1{
      font-family:'Noto Sans';
      font-size: 2rem;
}

.card-text-2{
      font-family:'Noto Sans';
      font-size: 2rem;
      font-weight: 500;
      color: #fff;
      line-height: 40px;

}

.card-text-mobile-1{
      font-family:'Noto Sans';
      font-size: 1.5rem;
}

.card-text-mobile-2{
      font-family:'Noto Sans';
      font-size: 1.5rem;
      font-weight: 500;
      color: #fff;
      line-height: 30px;

}

.contact-card{
  /* margin-top: 100px; */

  margin-bottom: 200px;
}

.text-1{
  font-family: "Noto Sans";
  color:#fff;
  text-align: center;
  line-height: 30px;
}
.fade-enter-active, .fade-leave-active {
  transition: all .5s;
  /* margin-top: 0; */
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
  /* margin-bottom: 300px; */
}
.pr-btn{
 /* background: linear-gradient(90deg, rgba(250,88,0,1) 0%, rgba(253,186,0,1) 100%); */
 background-image:linear-gradient(to right, #fa5800, #fdba00);
 background-size: 10rem 12rem;
 background-position: center;
 /* background-clip: padding-box; */
 color: white;
 font-family: "Noto Sans";
 font-weight: 600;
 padding: 10px;
 border: solid 5px transparent;
 border-radius: 2em;
}
.pr-btn:hover{
  border: solid 5px transparent;
  background-image: linear-gradient(white,white), linear-gradient(to right, #fa5800, #fdba00);
  color: gray;
  font-family: "Noto Sans";
  background-clip: padding-box, border-box;
  background-origin: border-box;
}

.input-range {
    -webkit-appearance: none;
    height: 200px;
    box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
    background: #706d7021;
    border-radius: 25px;
    border: 0px solid #000101;
    width: 3px;
    outline: none;
    writing-mode: bt-lr; /* IE */
    -webkit-appearance: slider-vertical; /* WebKit */
    transform: rotate(180deg) scaleX(-1);
    cursor: pointer;
    
  }
  .profil-range{
    height: 100%;
    margin-left: 20px;
  }

  .blue-card-range{
    position: absolute;
    right: 0;
    top: 50px;
    border-radius: 3px;

  }

   .orange-card-range{
    position: absolute;
    left: 0;
    top: 50px;
  }

  input[type="range"]::-moz-range-thumb {
    border: none; /*Removes extra border that FF applies*/
    border-radius: 0; /*Removes default border-radius that FF applies*/
    background-color: #F93A00;
    height: 3rem;
    width: 5px;
}
</style>